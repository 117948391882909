import React, { useState } from "react"

import { Menu, MenuToggle } from "."

const MenuContainer = () => {
  const [visibility, setVisibility] = useState(false)

  return (
    <div id="menu-container">
      <MenuToggle handleClick={() => setVisibility(!visibility)} />
      <Menu menuVisibility={visibility} />
    </div>
  )
}

export default MenuContainer
