import React from "react"
import { Link } from "gatsby"

const Menu = ({ menuVisibility }) => {
  let visibility = menuVisibility ? "visible" : "hide"

  return (
    <ul id="navbar-menu" className={visibility}>
      <li>
        <Link to={`/`} activeClassName={`active-nav-link`}>
          About
        </Link>
      </li>
      <li>
        <Link
          to={`/projects`}
          activeClassName={`active-nav-link`}
          partiallyActive={true}
        >
          Projects
        </Link>
      </li>
      <li>
        <Link
          to={`/resume`}
          activeClassName={`active-nav-link`}
          partiallyActive={true}
        >
          Resume
        </Link>
      </li>
      <li>
        <Link
          to={`/contact`}
          activeClassName={`active-nav-link`}
          partiallyActive={true}
        >
          Contact
        </Link>
      </li>
    </ul>
  )
}

export default Menu
