import React from "react"

import { Navbar, Main } from "."
import "../style/index.css"
import "@fortawesome/fontawesome-free/js/all"

const Layout = ({ location, title, children }) => {
  return (
    <div id="layout">
      <Navbar />
      <Main location={location} title={title} children={children} />
    </div>
  )
}

export default Layout
