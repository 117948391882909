import React from "react"

const Main = ({ children }) => {
  return (
    <div id="main">
      <main>{children}</main>
      <footer>© Jason Cho 2020</footer>
    </div>
  )
}

export default Main
