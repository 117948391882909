import React from "react"

const MenuIcon = props => {
  return (
    <button id="navbar-menu-toggle" onClick={props.handleClick}>
      <div />
      <div />
      <div />
    </button>
  )
}

export default MenuIcon
