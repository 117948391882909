import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import { LinkBox } from "."

const ProjectDescription = ({ node, fluid }) => {
  const title = node.frontmatter.title || node.fields.slug
  const { site, github, video } = node.frontmatter

  return (
    <div className="project-description">
      <Link className="project-pic" to={node.fields.slug}>
        <Image fluid={fluid} alt={title} />
      </Link>
      <div className="project-text">
        <header>
          <h3>
            <Link to={node.fields.slug}>{title}</Link>
          </h3>
        </header>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: node.frontmatter.description || node.excerpt,
            }}
          />
          <div className="links">
            {site && <LinkBox text="Site" href={site} width="100px" />}
            {github && <LinkBox text="GitHub" href={github} width="100px" />}
            {video && <LinkBox text="Video" href={video} width="100px" />}
          </div>
        </section>
      </div>
    </div>
  )
}

export default ProjectDescription
