import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import { MenuContainer } from "."

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query photoQuery {
      portrait: file(absolutePath: { regex: "/jason-cho.jpg/" }) {
        childImageSharp {
          fixed(width: 150, height: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
          }
          social {
            linkedin
            github
          }
        }
      }
    }
  `)
  const { author, social } = data.site.siteMetadata

  return (
    <aside>
      <div id="navbar">
        <div id="navbar-profile">
          <Image
            className="profile-pic"
            fixed={data.portrait.childImageSharp.fixed}
            alt={author.name}
          />
          <h1>
            <Link to={`/`}>Jason Cho</Link>
          </h1>
          <p>Full-stack developer in NYC</p>
          <div id="navbar-social">
            {Object.keys(social).map(type => (
              <a key={type} href={social[type]}>
                <i className={`icon fab fa-${type}`} />
              </a>
            ))}
          </div>
        </div>
        <hr style={{ width: rhythm(7) }} />
        <MenuContainer />
      </div>
    </aside>
  )
}

export default Navbar
